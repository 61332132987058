/* @import url("https://fonts.googleapis.com/css2?family=Salsa&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Salsa&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

/* @font-face {
  font-family: 'Nexa';
  src: url('./fonts/NexaHeavy.ttf') format('truetype');
}

@font-face {
  font-family: 'Averta';
  src: url('./fonts/AvertaRegular.ttf') format('truetype');
} */

h1,
h2,
h3,
h4,
h5,
h6,
button,
.MuiTableHead-root tr th,
input,
label,
button span,
.MuiAvatar-circular,
.MuiPaper-root ul li,
.MuiSelect-select {
  /* font-family: "Salsa", cursive !important; */
  /* font-family: 'Nexa' !important; */
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.MuiTableBody-root tr td,
p,
span,
a {
  /* font-family: "Mulish", sans-serif !important; */
  /* font-family: 'Averta' !important; */
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

a:hover {
  text-decoration: none;
}

.zoomed {
  transition: all 5s;
}


body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #5D87FF;
}